import { useMemo } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { ExperienceTracker } from '@atlassian/experience-tracker';

import { isClientFetchError } from '../../common/utils/is-error';
import { type RovoInstrumentedError } from '../../common/utils/rovo-instrumented-error';
import { logException } from '../../services/sentry';
import { useAnalytics } from '../analytics';

// Re-exporting some experience trackers packages
export { ExperienceTracker, ExperienceTrackerContext } from '@atlassian/experience-tracker';
export type { ExperienceTrackerAPI } from '@atlassian/experience-tracker/ExperienceTracker';

const isRovoInstrumentedError = (error: any): error is RovoInstrumentedError =>
	error &&
	typeof error === 'object' &&
	'errorAttributes' in error &&
	typeof error.errorAttributes === 'object';

class ExperienceTrackerHandler {
	private static instance: ExperienceTrackerHandler;
	public experienceTracker = new ExperienceTracker();
	private sendAnalyticsEvent: ReturnType<typeof useAnalytics>['sendAnalyticsEvent'] | null = null;

	constructor() {
		this.experienceTracker.subscribe((event) => {
			// for FailEvent
			if ('error' in event) {
				// do not fail experience tracking if the error is a client fetch error
				if (isClientFetchError(event.error)) {
					return;
				}

				let filteredErrorAttributes = {};

				if (event.attributes && fg('rovo_chat_analytics_attributes_dr_ws')) {
					const { webSearchEnabled, deepResearchEnabled } = event.attributes as Record<string, any>;

					if (webSearchEnabled !== undefined) {
						filteredErrorAttributes = {
							...filteredErrorAttributes,
							webSearchEnabled,
						};
					}

					if (deepResearchEnabled !== undefined) {
						filteredErrorAttributes = {
							...filteredErrorAttributes,
							deepResearchEnabled,
						};
					}
				}

				if (isRovoInstrumentedError(event.error)) {
					const errorAttributes = event.error.errorAttributes;

					// Remove undefined properties
					filteredErrorAttributes = Object.fromEntries(
						Object.entries(errorAttributes).filter(([_, value]) => value !== undefined),
					);

					// Filter 5xx errors since backend monitoring will cover this
					// as per https://hello.atlassian.net/wiki/spaces/CONVAI/pages/3547354267/Meeting+notes+FE+SLOs+which+one+to+promote
					if (errorAttributes?.statusCode && errorAttributes?.statusCode >= 500) {
						return;
					}
				}

				const error =
					event.error instanceof Error ? event.error : new Error(JSON.stringify(event.error));

				// this is added by `useExperienceTracker` below
				const touchpointSource = (event.attributes as Record<string, any> | undefined)
					?.touchpointSource;

				logException({
					exception: error,
					name: event.name,
					tags: {
						source: 'aiMateExperienceTracker',
						...filteredErrorAttributes,
						...(touchpointSource && {
							touchpointSource,
						}),
					},
				});
			}

			this.sendAnalyticsEvent?.(
				{
					eventType: 'operational',
					action: event.action,
					actionSubject: 'ui',
					attributes: {
						task: event.name,
						taskId: event.id,
						startTime: event.startTime,
						...event.attributes,
					},
				},
				{
					removeDefaultTags: true,
				},
			);
		});
	}

	setSendAnalyticsEvent(sendAnalyticsEvent: ReturnType<typeof useAnalytics>['sendAnalyticsEvent']) {
		this.sendAnalyticsEvent = sendAnalyticsEvent;
	}

	static getInstance() {
		if (!ExperienceTrackerHandler.instance) {
			ExperienceTrackerHandler.instance = new ExperienceTrackerHandler();
		}

		return ExperienceTrackerHandler.instance;
	}
}

type Params = {
	touchpointSource: string;
};

export const useAIMateExperienceTracker = (params?: Params): ExperienceTracker => {
	const { sendAnalyticsEvent } = useAnalytics();

	useMemo(() => {
		const experienceTracker = ExperienceTrackerHandler.getInstance();
		// this is not ideal, okay just because `sendAnalyticsEvent` is never change
		// since every hook instance will override the singleton sendAnalyticsEvent
		experienceTracker.setSendAnalyticsEvent(sendAnalyticsEvent);
	}, [sendAnalyticsEvent]);

	const experienceTrackerProxy = useMemo(() => {
		const proxyHandler = {
			get: (target: ExperienceTracker, prop: keyof ExperienceTracker) => {
				// Overriding the fail method to add touchpointSource to the attributes
				if (prop === 'fail') {
					return (failParams: Parameters<typeof target.fail>[0]) => {
						target.fail({
							...failParams,
							attributes: {
								...failParams.attributes,
								touchpointSource: params?.touchpointSource,
							},
						});
					};
				}

				return typeof target[prop] === 'function' ? target[prop].bind(target) : target[prop];
			},
		};

		return new Proxy(ExperienceTrackerHandler.getInstance().experienceTracker, proxyHandler);
	}, [params?.touchpointSource]);

	return experienceTrackerProxy;
};
